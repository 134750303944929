import styled from "styled-components";
import { motion } from "framer-motion";

export const SkillsCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const SkillsCard = styled(motion.div)`
  background: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: calc(33.333% - 1rem);
  @media (max-width: 768px) {
    width: calc(50% - 1rem);
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const PostImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px 8px 0 0;
`;

export const PostInfo = styled.div`
  padding: 1rem;
`;

export const Timestamp = styled.p`
  font-size: 0.75rem;
  color: #999;
  margin-top: 0.5rem;
`;
